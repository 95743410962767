import { SET_SHOWROOM_USER, LOGOUT, CLEAR_SHOWROOM_USER } from "../actions/type";

var initailState=[];

const showroomUser =( state = initailState , action) =>{
    switch (action.type) {
        case SET_SHOWROOM_USER:
            return [
                ...state,
                ...action.payload,
            ];
        case CLEAR_SHOWROOM_USER:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default showroomUser;