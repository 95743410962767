import React, { Component, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";
import Backbutton from "../../Backbutton"

//Table
import MUIDataTable from "mui-datatables";

import EditIcon from "@material-ui/icons/Edit";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LockIcon from "@material-ui/icons/Lock";
import { Tooltip } from "@material-ui/core";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import SweetAlert from "react-bootstrap-sweetalert";

import axios from "axios";

import { connect } from "react-redux";

import { setProductBucket } from "../../../actions/ProductBucketAction";
import ProductBucketAdd from "./ProductBucketAdd";

const ProductBucket = ({ history, server, access_token }) => {

  const [productBucket, setProductBucket] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllProductBucket();
  }, []);


  const getAllProductBucket = () => {

    setLoading(true)

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get('https://arihant.trikodev.xyz/' + "api/product-bucket", config)
      .then((response) => {
        setProductBucket(response.data);
        setLoading(false);
      })
      .catch((error) => { });
  };

  const deleteCall = (ids) => {

    setAlert(
      <SweetAlert
        warning
        title="Are you Sure ?"
        onConfirm={() => deleteRecord(ids)}
        onCancel={hideAlert}
        confirmBtnBsStyle="danger"
        cancelBtnStyle={{ color: "red" }}
        confirmBtnText="Delete!"
        cancelBtnText="Cancel"
        showCancel
      >
        This will Delete the Data.
      </SweetAlert>
    );
  };

  const deleteRecord = (ids) => {

    const parameter = {
      _method: "delete",
    };
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post('https://arihant.trikodev.xyz/' + `api/product-bucket/${ids}`, parameter, config)
      .then((response) => {
        hideAlert();
        getAllProductBucket();
        deleted();
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const deleted = () => {
    console.log("deleted call");
    setAlert(
      <SweetAlert success title="Deleted" onConfirm={hideAlert}>
        Data has been Deleted
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bucket_price",
      label: "Price",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "part_number",
      label: "Part Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (ids, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton
                  onClick={() =>
                    history.push(
                      "/ProductBucket/Edit",
                      productBucket.filter((item) => {
                        return item.id === ids;
                      })
                    )
                  }
                  aria-label="Edit"
                  style={{
                    backgroundColor: "#00ACC1",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Edit"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top" arrow>
                <IconButton
                  onClick={() => deleteCall(ids)}
                  aria-label="delete"
                  style={{
                    backgroundColor: "#FF0A37",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
        download: false,
      },
    },
  ];

  const options = {
    filterType: "multiselect",
    selectableRows: false,
    print: false,
    download: true,
  };

  return (
    <>
      <div className="dashboardMainDivContainer">
        {alert}
        <div className="card m-3 mt-5">
          <div className="titles">Product Buckets</div>
          <div style={{ textAlign: "end" }}>
            <Link className="BtnLinkDist" to="/ProductBucket/Add">
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#F05225' }}
                startIcon={<AddCircleIcon />}
              >
                Add New Product Bucket
              </Button>
            </Link>
          </div>

          {loading == true ?

            <div style={{ marginTop: "2%" }}>
              <Skeleton height={70} />
              <Skeleton count={15} style={{ marginTop: 15 }} />
            </div>
            :
            <div style={{ marginTop: "2%" }}>
              <MUIDataTable
                title={"List of all product buckets"}
                data={productBucket}
                columns={columns}
                options={options}
              />
            </div>
          }
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  product_bucket: state.product_bucket,
});

export default connect(mapStateToProps, { setProductBucket })(ProductBucket);
