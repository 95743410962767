import React, { Component, useEffect, useState } from "react";
//import TextField from "@material-ui/core/TextField";
//import AppBar from 'material-ui/AppBar';
//import RaisedButton from 'material-ui/RaisedButton';
// import TextField from 'material-ui/TextField';
import { Link, useHistory } from "react-router-dom";
import "../../assets/scss/Login.scss";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { ToastContainer, toast } from "react-toastify";
import Background from "../views/arihantbackground.jpg";
import Logo from "../views/arihant_black_logo.png";
import { CircularProgress } from '@material-ui/core';
import logo from "../../assets/img/arihant_logo.png";
import banner from "../../assets/img/ordering.jpg";

import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";

import SweetAlert from "react-bootstrap-sweetalert";
import "react-toastify/dist/ReactToastify.css";


//Redux
import { connect } from "react-redux";
import { login, logout } from "../../actions/authAction";
 

let schema = yup.object({
  email: yup.string().email().required("Email is Required").nullable(),
  password: yup.string().required("Password is Required").nullable(),
});

const Login = ({ history, server, login, role, auth, logout }) => {

  const [alert, setAlert] = useState(null);
  const [checked, setChecked] = React.useState(false);
  const [onLoading, setOnLoading] = React.useState(false);  

  const handleCheck = () => {
    checked ? setChecked(false) : setChecked(true);
  };

  const classes = useStyles();

  const hideAlert = () => {
    setAlert(null);
  };

  const alertcall = (errormessage) => {
    
    setAlert(
      <SweetAlert title="ERROR !" onConfirm={hideAlert}>
        {errormessage}
      </SweetAlert>
    );
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (!error.response) {
        toast.error("Network Error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (error.response.status === 401) {
          logout();
          history.push("/");
        }

        return Promise.reject(error);
      }
    }
  );
  useEffect(() => {
    if (auth) {
      console.log(role.id);
      if (role.id === 1) {
        history.push("/Dashboard");
      } else if (role.id === 2) {
        history.push("/Dashboard");
      } else if (role.id === 3) {
        history.push("/Dashboard");
      }
    }
  }, []);

  const handleLogin = (values) => {
    
    if (checked) {
      localStorage.setItem("email", values.email);
      localStorage.setItem("password", values.password);
    }
    const bodyParameters = {
      email: values.email,
      password: values.password,
    };

    setOnLoading(true)

    axios
      .post('https://arihant.trikodev.xyz/' + "api/auth/login", bodyParameters)
      .then((response) => {

        login(response.data);
        setOnLoading(false)

        if (response.data.role[0].id === 1) {
          history.push("/Dashboard");

        } else if (response.data.role[0].id === 2) {
          //history.push("/Dashboard");
          alertcall("Invalid login!");

        } else if (response.data.role[0].id === 3) {
          // history.push("/Dashboard");
          alertcall("Invalid login!");

        } else if (response.data.role[0].id === 4) {
          alertcall("Invalid login!");
        }
      })
      .catch(function (error) {
        setOnLoading(false)
        console.log(error);
        const errormessage = error.response.data.errors.authError[0];
        alertcall(errormessage);
      });
  };

  

  return (
    <div className={classes.page}>
      {alert}
      <div className={classes.formContainer}>
        <Formik
          initialValues={{
            email: localStorage.getItem("email"),
            password: localStorage.getItem("password"),
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            console.log(values);
            handleLogin(values);
          }}
        >
          {({
            handleChange,
            handleBlur,
            values,
            handleSubmit,
            isValid,
            errors,
            touched,
          }) => (
            
            <form
              className={classes.root}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
            <img src={logo} width="300" />
              <h3 style={{ marginTop: 0, textTransform:'uppercase' }}>Admin Login</h3>
              <TextField
                className={classes.text}
                id="standard-basic"
                label="Email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {errors.email && touched.email && (
                <p style={{ color: "red", marginTop: 0, fontSize: 11, marginLeft:30,
                textAlign:'left' }}>{errors.email}</p>
              )}
              <TextField
                className={classes.text}
                id="standard-basic"
                label="Password"
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {errors.password && touched.password && (
                <p style={{ color: "red", marginTop: 0, fontSize: 11, marginLeft:30,
                textAlign:'left' }}>{errors.password}</p>
              )}
              <div style={{ textAlign: "left", marginLeft: "25px" }}>
                <Checkbox checked={checked} onChange={handleCheck} />
                Remember Me
              </div>
              
              <Button
                style={{ margin: 20, width: "150px", paddingTop:10,
                backgroundColor:"#F05225",color:"#fff",}}
                variant="contained"
                type="submit"
                disabled={!isValid}
              >
                {onLoading ? <CircularProgress style={{color:'white'}} /> : "LOGIN"}
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
  server: state.auth.server,
  auth: state.auth.auth,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
});

export default connect(
  mapStateToProps,
  { login, logout } 
)(Login);

const useStyles = makeStyles({
  root: {
    padding: 20,
    color: "black",
  },
  text: {
    width: "250px",
    margin: 10,
    color: "black",
  },
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    margin: "auto",
    minHeight: "100vh",
    minWidth: "100vw",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    background: "#ffffff",
    padding: "25px 25px",
    width: "400px",
    textAlign: "center",   
    border: "1px solid rgba(209, 213, 219, 0.3)",
  },
});

 