import axios from 'axios';
import {CLEAR_FITMENT_FOR , SET_FITMENT_FOR } from "./type";

export const setFitmentFor = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_FITMENT_FOR,
    });
    dispatch({
        type:SET_FITMENT_FOR,
        payload:data,
    })
}
