import { SET_ORDER, LOGOUT, CLEAR_ORDER } from "../actions/type";

var initailState=[];

const order =( state = initailState , action) =>{
    switch (action.type) {
        case SET_ORDER:
            return [
                ...state,
                ...action.payload,
            ];
        case CLEAR_ORDER:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default order;