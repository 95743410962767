import axios from 'axios';
import {CLEAR_CATEGORY , SET_CATEGORY } from "./type";

export const setCategory = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_CATEGORY,
        payload:data,
    });
    dispatch({
        type:SET_CATEGORY,
        payload:data,
    })
}