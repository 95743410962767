import axios from 'axios';
import {CLEAR_INVENTORY , SET_INVENTORY } from "./type";

export const setInventory = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_INVENTORY,
        payload:data,
    });
    dispatch({
        type:SET_INVENTORY,
        payload:data,
    })
}
