import axios from 'axios';
import {CLEAR_ORDER , SET_ORDER } from "./type";

export const setOrder = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_ORDER,
    });
    dispatch({
        type:SET_ORDER,
        payload:data,
    })
}
