import axios from 'axios';
import {CLEAR_APPLICABLE_FOR , SET_APPLICABLE_FOR } from "./type";

export const setApplicableFor = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_APPLICABLE_FOR,
    });
    dispatch({
        type:SET_APPLICABLE_FOR,
        payload:data,
    })
}
