import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";

import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Backbutton from "../Backbutton";
import { CircularProgress } from '@material-ui/core';

import { connect } from "react-redux";
import { logout } from "../../actions/authAction";

import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let schema = yup.object().shape({
  password: yup
    .string()
    .required("This field is required")
    .min(5, ({ min }) => `Password must be at least ${min} characters`),
  confirm: yup
    .string()
    .required("This field is required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords not matching"),
    })
    .min(5, ({ min }) => `Password must be at least ${min} characters`),
});

function ChangePassword({ server, history, access_token, location }) {

  const [alert, setAlert] = useState(null);
  const [onLoading, setOnLoading] = React.useState(false);

  const handleFormSubmit = (values) => {

    let bodyParameters;
    if (location.state) {
      bodyParameters = {
        password: values.password,
        user_id: location.state[0].id,
      };
    } else {
      bodyParameters = {
        password: values.password,
      };
    }

    setOnLoading(true);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post('https://arihant.trikodev.xyz/' + "api/user/reset_password", bodyParameters, config)
      .then((response) => {

          setOnLoading(false);

          toast('Password changed', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });         

          history.push('/Users');
       
      })
      .catch(function (error) {
        const errormessage = error.response.data.errors.authError[0];
        setAlert(
          <SweetAlert error title="Password Changed" onConfirm={hideAlert}>
            {errormessage}
          </SweetAlert>
        );
      });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <div className="grayBackgroundBox">
      {alert}
      <div className="card container">
        <Formik
          initialValues={{ password: "", confirm: "" }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            handleFormSubmit(values);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form onSubmit={handleSubmit} className="container">
              <Backbutton />
              <div className="title">Change Password</div>
              <div>
                <div className="column">
                  <div className="inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="New Password"
                      name="password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <p style={{ color: "red" }}>{errors.password}</p>
                    )}
                  </div>
                  <div className="inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Confirm New Password"
                      name="confirm"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirm}
                    />
                    {errors.confirm && touched.confirm && (
                      <p style={{ color: "red" }}>{errors.confirm}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center mt-5" style={{ textAlign: "center" }}>
                <Button
                  className="formSubmit"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isValid || onLoading}
                  style={{paddingTop:10}}
                  disableElevation
                >
                 {onLoading ? <CircularProgress style={{color:'white'}} /> : "Change Password and Activate User"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
});

export default connect(mapStateToProps, {})(ChangePassword);
