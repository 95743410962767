import axios from 'axios';
import {CLEAR_PRODUCT_BUCKET , SET_PRODUCT_BUCKET } from "./type";

export const setProductBucket = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_PRODUCT_BUCKET,
    });
    dispatch({
        type:SET_PRODUCT_BUCKET,
        payload:data,
    })
}
