import React, { Component, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
// import BrandAdd from "./BrandAdd"
import { Link } from "react-router-dom";

//Table
import MUIDataTable from "mui-datatables";

import EditIcon from "@material-ui/icons/Edit";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LockIcon from "@material-ui/icons/Lock";
import { Tooltip } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { connect } from "react-redux";
import { setInventory } from "../../../actions/InvetoryAction";
import { setBrand } from "../../../actions/BrandAction";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const Inventory = ({
  history,
  server,
  access_token,
  setInventory,
  inventory,
  brands,
}) => {


  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const [temp, setTemp] = useState(inventory);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  //ComponentDidMount
  useEffect(async () => {
    await getAllBrand();
    await getAllInventory(1,10);
  }, []);

  //ComponentDidUpdate when brand variable change
  useEffect(() => {
    setTemp(inventory);
  }, [inventory]);

  const getAllBrand = () => {

    setLoading(true);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get('https://arihant.trikodev.xyz/' + "api/brand", config)
      .then((response) => {
        setBrand(response.data);
      })
      .catch((error) => { });
  };

  const getAllInventory = (page, rowsPerPage) => {

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    

    axios
      .get('https://arihant.trikodev.xyz/' + `api/inventory`, config) 
      //.get('https://arihant.trikodev.xyz/' + `api/inventory?page=${page}&rowsPerPage=${rowsPerPage}`, config)
      .then((response) => {
        setInventory(response.data.inventories);
       // setCount(response.data.totalCount);
        setLoading(false);
      })
      .catch((error) => { });
  };

  const deleteCall = (ids) => {

    setAlert(
      <SweetAlert
        warning
        title="Are you sure you want to delete this product?"
        onConfirm={() => deleteRecord(ids)}
        onCancel={hideAlert}
        confirmBtnBsStyle="danger"
        cancelBtnStyle={{ color: "red" }}
        confirmBtnText="Delete!"
        cancelBtnText="Cancel"
        showCancel
      >
        This action cannot be reverted.
      </SweetAlert>
    );
  };

  const deleteRecord = (ids) => {

    const parameter = {
      _method: "delete",
    };

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post('https://arihant.trikodev.xyz/' + `api/inventory/${ids}`, parameter, config)
      .then((response) => {

        hideAlert();
        getAllInventory();
        deleted();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleted = () => {

    setAlert(
      <SweetAlert success title="Deleted" onConfirm={hideAlert}>
        Product deleted
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const columns = [
    {
      name: "id",
      label: "#",
      options: {
        display: true,
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "size",
      label: "Size",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == 'None' || value == null || value == 'null') {
            return <>---</>;
          } else {
            return value;
          }
        },
      },
    },
    {
      name: "model",
      label: "Model",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == 'None' || value==null || value=='null' || value == 'Nulll') {
            return <>---</>;
          } else {
            return value;
          }
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "color",
      label: "Color",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {

          if (value == null || value=='null' || value == 'Null') {
            return <>---</>;
          } else {
            return value;
          }
        },
      },
    },
    {
      name: "brand_id",
      label: "Brand",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (ids, tableMeta, updateValue) => {
          if (ids) {
            return <>{brands.filter((item) => item.id == ids)[0]?.name}</>;
          }
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "200px" } }),

        customBodyRender: (ids, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton
                  onClick={() =>
                    history.push(
                      "/inventory/edit",
                      inventory.filter((item) => {
                        return item.id === ids;
                      })
                    )
                  }
                  aria-label="Edit"
                  style={{
                    backgroundColor: "#00ACC1",
                    padding: 5,
                    marginRight: 10,
                    color: "#ffffff",
                  }}
                  className="Edit"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top" arrow>
                <IconButton
                  onClick={() => deleteCall(ids)}
                  aria-label="delete"
                  style={{
                    backgroundColor: "#FF0A37",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
        download: false,
      },
    },
  ];

  const options = {
    filterType: "multiselect",
    selectableRows: false,
    print: false,
    download: true,
    serverSide: false,
    //count: count,
   // page: page,
    //rowsPerPage: rowsPerPage,
    //onTableChange: (action, tableState) => {
     // if (action === 'changePage' || action === 'changeRowsPerPage') {
    //    const { page, rowsPerPage } = tableState;
   //     setPage(page);
    //    setRowsPerPage(rowsPerPage);
   //     getAllInventory(page, rowsPerPage);
    //  }
   // },
    onSearchChange: (searchText) => {


      if(searchText){
        
        // Trim the search text
        const trimmedSearchText = searchText.trim();
        console.log("Trimmed Search Text:", trimmedSearchText);
      }
    },
  };

  return (
    <>
      <div className="dashboardMainDivContainer">
        {alert}
        <div className="card m-3 mt-5">
          <div className="titles">Inventory</div>
          <div style={{ textAlign: "end" }}>
            <Link className="BtnLinkDist" to="/Inventory/Add">
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#F05225' }}
                startIcon={<AddCircleIcon />}
              >
                Add New Inventory
              </Button>
            </Link>
          </div>

          {loading == true ?

            <div style={{ marginTop: "2%" }}>
              <Skeleton height={70} />
              <Skeleton count={15} style={{ marginTop: 15 }} />
            </div>
            :
            <div style={{ marginTop: "2%" }}>
              <MUIDataTable
                title={"List of all Inventory"}
                data={temp}
                columns={columns}
                options={options}
              />
            </div>
          }

        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  inventory: state.inventory,
  brands: state.brand,
});

export default connect(mapStateToProps, { setInventory, setBrand })(Inventory);
