import axios from 'axios';
import {CLEAR_BRAND , SET_BRAND } from "./type";

export const setBrand = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_BRAND,
    });
    dispatch({
        type:SET_BRAND,
        payload:data,
    })
}
