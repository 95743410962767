import axios from "axios";
import { CLEAR_SHOWROOM_USER, SET_SHOWROOM_USER } from "./type";

export const setShowroomUser = (data) => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_SHOWROOM_USER,
    payload: data,
  });
  dispatch({
    type: SET_SHOWROOM_USER,
    payload: data,
  });
};
