import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Grid,
  TextareaAutosize,
  Typography,
  Box,
  Modal,
} from "@material-ui/core";
import Backbutton from "../../Backbutton";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";
import ImageUploader from "react-images-upload";
import FormData from "form-data";
import { CircularProgress } from '@material-ui/core';


let schema = yup.object({
  productbucketname: yup.string().required("Name is required").typeError(""),
  bucket_price: yup.number().required("Price is required").typeError("Invalid price"),
  part_number: yup.string().required("Part number is required").typeError("Invalid part number"),
});

function ProductBucketAdd({ server, history, access_token, role }) {

  const [errortext, seterrortext] = useState("");
  const [alert, setAlert] = useState(null);
  const [image, setImage] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [onLoading, setOnLoading] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (role?.id !== 1) {
      if (role?.id === 2) {
        history.push("/Distributer");
      }
      if (role?.id === 3) {
        history.push("/Retailer");
      }
      if (role?.id === 4) {
        history.push("/Games");
      }
    }
  }, []);

  const hideAlert = () => {
    setAlert(null);
  };

  const added = () => {
    setAlert(
      <SweetAlert success title="Added" onConfirm={hideAlert}>
        Product bucket has been created
      </SweetAlert>
    );

    history.push('/ProductBucket')
  };

  const addNewProductBucket = (values, resetForm) => {

    setOnLoading(true);
    
    const bodyParameters = {
      name: values.productbucketname,
      bucket_price:values.bucket_price,
      part_number:values.part_number,
    };

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    const data = new FormData();

    Object.keys(bodyParameters).forEach((key) => {
      data.set(key, bodyParameters[key]);
    });   

    axios
      .post('https://arihant.trikodev.xyz/' + "api/product-bucket", data, config)
      .then((response) => {
        
        added();
        resetForm();
        setImage(null);
        seterrortext("");
        setOnLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.errors);
        const errormessage = error.response.data.errors.alias[0];
        console.log(errormessage);
        seterrortext(errormessage);
      });
  };

  const handleImageChange = (picture) => {
    console.log(picture);
    setImage(picture);
  };

  return (
    <div className="grayBackgroundBox">
      {alert}
      <div className="card container">
        <Formik
          initialValues={{
            productbucketname: "",     
            bucket_price:"",       
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            addNewProductBucket(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form className="container" onSubmit={handleSubmit}>
              <div>
                <Backbutton />
              </div>
              <div className="title">Add New Product Bucket</div>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div>
                    <div className="row">
                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          className="inputField"
                          id="standard-basic"
                          label="Product bucket name*"
                          name="productbucketname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.productbucketname}
                        />
                        {errors.productbucketname && (
                          <p style={{ color: "red" }}>{errors.productbucketname}</p>
                        )}
                      </div>

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          className="inputField"
                          id="standard-basic"
                          label="Price*"
                          name="bucket_price"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.bucket_price}
                        />
                        {errors.productbucketname && (
                          <p style={{ color: "red" }}>{errors.bucket_price}</p>
                        )}
                      </div>

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          className="inputField"
                          id="standard-basic"
                          label="Part Number*"
                          name="part_number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.part_number}
                        />
                        {errors.part_number && (
                          <p style={{ color: "red" }}>{errors.part_number}</p>
                        )}
                      </div>
                      
                      </div>

                  </div>
                </Grid>
                
              </Grid>
              <div style={{ textAlign: "center" }} className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={!isValid || onLoading}
                  style={{backgroundColor:'#F05225'}}
                  disableElevation
                >
                  {onLoading ? <CircularProgress style={{color:'white'}} /> : "Save Product Bucket"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
        
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
});

export default connect(mapStateToProps, {})(ProductBucketAdd);