import axios from "axios";
import { CLEAR_GENERIC_USER, SET_GENERIC_USER } from "./type";

export const setGenericUser = (data) => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_GENERIC_USER,
    payload: data,
  });
  dispatch({
    type: SET_GENERIC_USER,
    payload: data,
  });
};
