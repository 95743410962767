import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { goBack } from 'react-router-redux';
import {withRouter}from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

class BackButton extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return <Button style={{borderRadius:"10px",color:"black"}} onClick={this.props.history.goBack}  variant="contained" component="span">
        <ArrowBackIcon/>
      </Button>;;
    }
}

export default withRouter(BackButton);
// onClick={this.props.history.goBack}