import React from "react";
import {Route,Redirect} from "react-router-dom";
import MUIDrawer from './components/sidebar/drawer';
import { makeStyles } from '@material-ui/core';
import { connect } from "react-redux";



const useStyles = makeStyles({
  container: {
    display: "flex"
  }
});


const PrivateRoute = ({
  auth,
  component: Component,
  ...rest
}) => {
const classes = useStyles();
  
  return (
      <Route {...rest} component={(props)=>(
        auth ? (
      <div className={classes.container}>

            <MUIDrawer />
            <Component {...props} />
          </div>
        ) : <Redirect to="/" />
      )} />
      )
  }

  const mapStateToProps =(state) => ({
    auth:state.auth.auth,
  })

  export default connect(mapStateToProps,{})(PrivateRoute);

//export default PrivateRoute;