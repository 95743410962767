import React, { Component, useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
// import BrandAdd from "./BrandAdd"
import { Link } from "react-router-dom";
import Backbutton from "../../Backbutton";

//Table
import MUIDataTable from "mui-datatables";

import EditIcon from "@material-ui/icons/Edit";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LockIcon from "@material-ui/icons/Lock";
import { formatMs, ListItemIcon, Tooltip } from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './orders.css';

import SweetAlert from "react-bootstrap-sweetalert";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import axios from "axios";
import { connect } from "react-redux";
import { setOrder } from "../../../actions/OrderAction";
import ReactToPrint from 'react-to-print';
import order from "../../../reducers/order";
import Modal from 'react-modal';
import { CircularProgress } from '@material-ui/core';
import { JsonToExcel } from "react-json-to-excel";
import { DownloadTableExcel } from 'react-export-table-to-excel';

const Orders = ({ history, server, access_token, setOrder, orders }) => {

  const [temp, setTemp] = useState(orders);

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [tempOrderDetails, setTempOrderDetails] = useState(null);
  const [alert, setAlert] = useState(null);
  const [isDetailVisible, setIsDetailVisible] = useState(false)
  const [currentOrder, setCurrentOrder] = useState(null)
  const [loading, setLoading] = useState(false);
  const [changingStatus, setChangingStatus] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [orderStatus, setOrderStatus] = useState('')
  const [currentStatus, setCurrentStatus] = useState('')
  const [commentsEmpty, setCommentsEmpty] = useState(false)
  const [statusError, setStatusError] = useState(false)
  const [showCreateOrder, setShowCreateOrder] = useState(false)  

  const [isProductsLoading, setIsProductsLoading] = useState(false)
  const [products, setProducts] = useState([])    

  const [orderItems, setOrderItems] = useState([])

  const tableRef = useRef(null);

  const [comment, setComment] = useState('');

  const handleCommentChange = event => {

    setComment(event.target.value);
  };

  const componentRef = useRef();

  const backToOrders = () => {
    setIsDetailVisible(false)
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  } 


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '40%',
      transform: 'translate(-50%, -50%)',
    },
  };  


  useEffect(() => {

    getAllOrder(1,10);

  }, []);

  useEffect(() => {
    getAllOrder(page, rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {

    if (isDetailVisible == false) {

      getAllOrder();
    }

  }, [isDetailVisible]);


  const handleExportCSV = () => {

    const csvHeaders = [
      "S.No.", "Product ID", "Category", "Brand", "Product", "Fitment For",
      "Color", "Model", "Unit Price", "Quantity"
    ];    

  
    const orderNumber = currentOrder.id; 
    const customerName = currentOrder.user.name; 
    const address = currentOrder.user.address; 
  
    var orderValues = [];
  
 /*   for (var i = 0; i < currentOrder.order_items.length; i++) {

      var theObj = [
        i + 1,
        currentOrder.order_items[i].product.id,
        currentOrder.order_items[i].category,
        currentOrder.order_items[i].brand,
        currentOrder.order_items[i].product.name,
        (currentOrder.order_items[i].fitment_for && currentOrder.order_items[i].fitment_for !== 'null') ? currentOrder.order_items[i].fitment_for : '---',
        (currentOrder.order_items[i].color && currentOrder.order_items[i].color !== 'null') ? currentOrder.order_items[i].color : '---',
        (currentOrder.order_items[i].model && currentOrder.order_items[i].model !== 'null') ? currentOrder.order_items[i].model : '---',
        currentOrder.order_items[i].unit_price,
        currentOrder.order_items[i].quantity,
      ];
  
      orderValues.push(theObj.join(",")); // Join the order item data with commas
    }
    */
   // Initialize an empty dictionary to hold items by category
var categorizedItems = {};

// Iterate over the order items and populate the dictionary
for (var i = 0; i < currentOrder.order_items.length; i++) {
    var item = currentOrder.order_items[i];
    var category = item.category;

    // Initialize the category array if it doesn't exist
    if (!categorizedItems[category]) {
        categorizedItems[category] = [];
    }

    // Prepare the item data
    var theObj = [
        i + 1,
        item.product.id,
        category,
        item.brand,
        item.product.name,
        (item.fitment_for && item.fitment_for !== 'null') ? item.fitment_for : '---',
        (item.color && item.color !== 'null') ? item.color : '---',
        (item.model && item.model !== 'null') ? item.model : '---',
        item.unit_price,
        item.quantity,
    ];

    // Add the item data to the corresponding category array
    categorizedItems[category].push(theObj.join(","));
}

// Initialize the orderValues array
var orderValues = [];

// Iterate over the categorized items and push the items into orderValues
for (var category in categorizedItems) {
    if (categorizedItems.hasOwnProperty(category)) {
        orderValues.push(...categorizedItems[category]);
    }
}

    /////////////
    if(currentOrder.custom_item_name_1){

      var theObj = [
        i + 1,
        '',
        '',
        '',
        currentOrder.custom_item_name_1,
        '',
        '',
        '',
        '',
        currentOrder.custom_item_qty_1,
      ];

      orderValues.push(theObj.join(","));
    }

    if(currentOrder.custom_item_name_2){

      var theObj = [
        i + 1,
        '',
        '',
        '',
        currentOrder.custom_item_name_2,
        '',
        '',
        '',
        '',
        currentOrder.custom_item_qty_2,
      ];

      orderValues.push(theObj.join(","));
    }


    if(currentOrder.custom_item_name_3){

      var theObj = [
        i + 1,
        '',
        '',
        '',
        currentOrder.custom_item_name_3,
        '',
        '',
        '',
        '',
        currentOrder.custom_item_qty_3,
      ];
      orderValues.push(theObj.join(","));
    }



    if(currentOrder.custom_item_name_4){

      var theObj = [
        i + 1,
        '',
        '',
        '',
        currentOrder.custom_item_name_4,
        '',
        '',
        '',
        '',
        currentOrder.custom_item_qty_4,
      ];

      orderValues.push(theObj.join(","));
    }



    if(currentOrder.custom_item_name_5){

      var theObj = [
        i + 1,
        '',
        '',
        '',
        currentOrder.custom_item_name_5,
        '',
        '',
        '',
        '',
        currentOrder.custom_item_qty_5,
      ];

      orderValues.push(theObj.join(","));
    }


    if(currentOrder.custom_item_name_6){

      var theObj = [
        i + 1,
        '',
        '',
        '',
        currentOrder.custom_item_name_6,
        '',
        '',
        '',
        '',
        currentOrder.custom_item_qty_6,
      ];

      orderValues.push(theObj.join(","));
    }



    if(currentOrder.custom_item_name_7){

      var theObj = [
        i + 1,
        '',
        '',
        '',
        currentOrder.custom_item_name_7,
        '',
        '',
        '',
        '',
        currentOrder.custom_item_qty_7,
      ];

      orderValues.push(theObj.join(","));
    }



    if(currentOrder.custom_item_name_8){

      var theObj = [
        i + 1,
        '',
        '',
        '',
        currentOrder.custom_item_name_8,
        '',
        '',
        '',
        '',
        currentOrder.custom_item_qty_8,
      ];

      orderValues.push(theObj.join(","));
    }



    if(currentOrder.custom_item_name_9){

      var theObj = [
        i + 1,
        '',
        '',
        '',
        currentOrder.custom_item_name_9,
        '',
        '',
        '',
        '',
        currentOrder.custom_item_qty_9,
      ];

      orderValues.push(theObj.join(","));
    }


    if(currentOrder.custom_item_name_10){

      var theObj = [
        i + 1,
        '',
        '',
        '',
        currentOrder.custom_item_name_10,
        '',
        '',
        '',
        '',
        currentOrder.custom_item_qty_10,
      ];

      orderValues.push(theObj.join(","));
    }
    
            
    ///////////////////


  
    const csvContent = [
      "Order Number, " + orderNumber,
      "Customer Name, " + customerName,
      "Address, " + address,
      "",
      csvHeaders.join(","),
      ...orderValues // Spread the orderValues array to create separate rows
    ].join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Order_ID_"+currentOrder.id+".csv";
    link.click();
  };
  
  

  useEffect(() => {

    setTemp(orders);

  }, [orders]);


  const openOrderDetail = (id) => {

    for (var i = 0; i < orders.length; i++) {

      if (orders[i].id == id) {

        setCurrentOrder(orders[i]);
        setCurrentStatus(orders[i].status)
        setIsDetailVisible(true);        


        // Set order items array for tally export
        var orderItemsArray = [];

        for (var j = 0; j < orders[i].order_items.length; j++) {

          if (orders[i].order_items[j].product) {

            var obj = {};
            obj['Vch No.'] = '0000' + orders[i].id;
            obj['Vch Type'] = "Sales";
            obj.Date = formatDate(new Date(orders[i].order_items[j].created_at));

            if(orders[i].order_items[j].product.product_bucket && orders[i].order_items[j].product.product_bucket.part_number){
                obj.Code = orders[i].order_items[j].product.product_bucket.part_number;
            }else{
                obj.Code = '';
            }

            obj.Name = orders[i].user.name;
            obj.Address1 = orders[i].user.address;
            obj.Address2 = '';
            obj.State = orders[i].user.state;
            obj['Pin Code'] = orders[i].user.pincode;
            obj['Regn Type'] = '';
            obj['GST No.'] = '';
            obj['Party Type'] = '';
            obj.Ecommerce = 'No';
            obj['Item Name'] = orders[i].order_items[j].product.name;
            obj['Godown Name'] = ''
            obj.Unit = 'Pcs';
            obj.Qty = orders[i].order_items[j].quantity;
            obj.Rate = '';
            obj.Amt = '';
            obj['Tax Type'] = '';
            obj.TaxRate = '';
            obj.IGST = '';
            obj.CGST = '';
            obj.SGST = '';
            obj['Round-Off'] = '';
            obj['Other Charges'] = '';

            orderItemsArray.push(obj);
          }
        }

        setOrderItems(orderItemsArray)
      }
    }
  }


  const changeOrderStatus = (id, comment) => {

    if (orderStatus == '') {

      setStatusError(true);
    } else if (comment == '') {

      setCommentsEmpty(true);
    } else {

      setChangingStatus(true);

      var formData = new FormData();

      formData.append("order_id", id);
      formData.append("status", orderStatus);
      formData.append("comment", comment);

      const requestOptions = {
        method: 'POST',
        body: formData,
      };

      fetch('https://arihant.trikodev.xyz/' + 'api/change-order-status', requestOptions)
        .then(response => response.json())
        .then(data => notifySuccess());
    }
  }


  const notifySuccess = () => {

    setCurrentStatus(orderStatus);
    setChangingStatus(false);
    setIsOpen(false);
    setComment('');

    toast('Order status changed', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }



  //A function for formatting a date to MMddyy
  function formatDate(d) {
    //get the month
    var month = d.getMonth();
    //get the day
    //convert day to string
    var day = d.getDate().toString().padStart(2, '0');
    //get the year
    var year = d.getFullYear();

    //pull the last two digits of the year
    year = year.toString().substr(-2);

    //increment month by 1 since it is 0 indexed
    //converts month to a string
    month = (month + 1).toString().padStart(2, '0');

    //return the string "MMddyy"
    return day + '-' + month + '-' + year;
  }


  
  const getAllOrder = (page, rowsPerPage) => {
    setLoading(true);
  
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };
  
    axios
     // .get(`https://arihant.trikodev.xyz/api/order?page=${page}&rowsPerPage=${rowsPerPage}`, config)
      .get(`https://arihant.trikodev.xyz/api/order`, config)
      .then((response) => {
        var tmpOrders = [];

        console.log("response.data:",response.data)
  
        for (var i = 0; i < response.data.orders.length; i++) { // Assuming the response has 'orders' array
          var obj = response.data.orders[i];
          obj.username = obj.user.name + '(#' + obj.user.id + ')';
          obj.placed_at = (new Date(obj.created_at)).toLocaleDateString() +
            ' ' + (new Date(obj.created_at)).toLocaleTimeString();
  
          obj.order_total = '₹' + obj.order_total.toFixed(2);
          obj.address = obj.user.address + ', ' + obj.user.district
            + ', ' + obj.user.state;
  
          tmpOrders.push(obj);
        }
  
        setOrder(tmpOrders);
        ///setCount(response.data.totalCount); // Assuming your API returns total count
        setLoading(false);
      })
      .catch((error) => {
        console.log('Error: ' + error);
        setLoading(false);
      });
  };
  

  const hideAlert = () => {
    setAlert(null);
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "username",
      label: "Placed By",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "order_total",
      label: "Order Total",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "placed_at",
      label: "Placed At",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (id, tableMeta, updateValue) => {
          return (
            <>

              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#F05225' }}
                onClick={() => openOrderDetail(id)}
              >
                View Order
              </Button>
            </>
          );
        },
      },
    },
  ];

  /*const options = {
    filterType: "multiselect",
    selectableRows: false,
    print: false,
    download: true,    
    serverSide: true,
  };    */  
  
  const options = {
    filterType: "multiselect",
    selectableRows: false,
    print: false,
    download: true,
    serverSide: false,
    //count: count,
    //page: page,
    //rowsPerPage: rowsPerPage,
   // onTableChange: (action, tableState) => {
   //   if (action === 'changePage' || action === 'changeRowsPerPage') {
   //     const { page, rowsPerPage } = tableState;
    //    setPage(page);
    //    setRowsPerPage(rowsPerPage);
    //    getAllOrder(page, rowsPerPage);
    //  }
    //}
  };


  const columnsOrderDetails = [
    {
      name: "id",
      label: "#",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex; // Get the index of the current row
          return rowIndex + 1; // Display the row number
        },
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return value !== 'null' ? value : "";
        },
      },
    },
    {
      name: "brand",
      label: "Brand",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return value !== 'null' ? value : "";
        },
      },
    },
    {
      name: "product",
      label: "Product",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (value && value !== 'null') ? value.name : "---"; 
        },
      },
    },    
    {
      name: "fitment_for",
      label: "Fitment For",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {         

          return (value!==null && value !== 'null' && value!=='Null') ? value : "---"; 
        },
      },
    },
    {
      name: "color",
      label: "Color",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return value !== 'null' ? value : ""; 
        },
      },
    },
    {
      name: "model",
      label: "Model",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return value !== 'null' ? value : ""; 
        },
      },
    },
    {
      name: "unit_price",
      label: "Unit Price",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return value !== 'null' ? value : ""; 
        },
      },
    },
    {
      name: "quantity",
      label: "Quantity",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return value !== 'null' ? value : ""; 
        },
      },
    },
    
  ];

  const optionsOrderDetails = {
    filterType: "multiselect",
    selectableRows: false,
    print: false,
    download: false,
    csv: false,
    downloadOptions:{
      filename: 'order-details.csv', 
      separator: ','
    }
  }; 



  return (
    <>
      <div className="dashboardMainDivContainer">
        {alert}
        <div className="card m-3 mt-5">

          <div className="titles">Orders</div>

          {loading == true ?

            <div style={{ marginTop: "2%" }}>
              <Skeleton height={70} />
              <Skeleton count={15} style={{ marginTop: 15 }} />
            </div>
            :

            <div style={{ marginTop: "2%", }}>

              {!isDetailVisible ?

                <div style={{ flexDirection: 'column' }}>

                  <MUIDataTable
                    data={temp}
                    columns={columns}
                    options={options}
                  />

                </div>
                :

                <div style={{ marginBottom: 100 }}>

                  <div className="main-div"
                    ref={componentRef} style={{ display: 'flex', flexDirection: 'column' }}>                     

                    <span style={{ marginLeft: 0, marginTop: 0 }}>Order #0000{currentOrder.id}</span>
                    <span style={{ marginLeft: 0, marginTop: 0 }}><strong>Customer Name:</strong> {currentOrder.user.name}</span>
                    <span style={{ marginLeft: 0, marginTop: 0 }}><strong>Address:</strong> {currentOrder.user.address}, 
                    <br/>{currentOrder.user.district},<br/>{currentOrder.user.state}</span><br/>
                    
                    <div style={{backgroundColor:'black', height:1, marginTop:10, marginBottom:10}}></div>                    


                <div style={{ flexDirection: 'column' }}>

                  <MUIDataTable
                    data={currentOrder.order_items}
                    columns={columnsOrderDetails}
                    options={optionsOrderDetails}
                  />

                </div>    

                {currentOrder && (currentOrder.custom_item_name_1 ||
                currentOrder.custom_item_name_2 ||
                currentOrder.custom_item_name_3 ||
                currentOrder.custom_item_name_4 ||
                currentOrder.custom_item_name_5 ||
                currentOrder.custom_item_name_6 ||
                currentOrder.custom_item_name_7 ||
                currentOrder.custom_item_name_8 ||
                currentOrder.custom_item_name_9 ||
                currentOrder.custom_item_name_10) &&
                <>

                 <h3 style={{marginTop:20, marginLeft:30}}>Items that could not be found</h3>


                <table className="unavailableItemsTable" style={{backgroundColor:'#F4F4FA', padding:10, marginLeft:30}} border="0">

         <thead>
             <th style={{textAlign:'left'}}>Item Name</th>
             <th style={{textAlign:'center'}}>Quantity</th>
         </thead>
         

         <tbody>

          {currentOrder.custom_item_name_1 &&
          <>
            <tr>
             <td style={{width:600,}}> {currentOrder.custom_item_name_1}
             </td>
             <td style={{width:100, textAlign:'center'}}>{currentOrder.custom_item_qty_1}
             </td>
            </tr>
            
             </>
          }
          

          {currentOrder.custom_item_name_2 &&
          <>
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_2}
             </td>
             <td style={{width:100, textAlign:'center'}}>{currentOrder.custom_item_qty_2}
             </td>
            </tr>
            
            </>
          }


          {currentOrder.custom_item_name_3 &&
          <>
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_3}
             </td>
             <td style={{width:100, textAlign:'center'}}>{currentOrder.custom_item_qty_3}
             </td>
            </tr>
            
            </>
          }

            {currentOrder.custom_item_name_4 &&
            <>
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_4}
             </td>
             <td style={{width:100, textAlign:'center'}}>{currentOrder.custom_item_qty_4}
             </td>
            </tr>
            
            </>
            }

            {currentOrder.custom_item_name_5 &&
            <>
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_5}
             </td>
             <td style={{width:100, textAlign:'center'}}>{currentOrder.custom_item_qty_5}
             </td>
            </tr>
            
            </>
            }

            {currentOrder.custom_item_name_6 &&
            <>
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_6}
             </td>
             <td style={{width:100, textAlign:'center'}}>{currentOrder.custom_item_qty_6}
             </td>
            </tr>
           
            </>
            }

            {currentOrder.custom_item_name_7 &&
            <>
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_7}
             </td>
             <td style={{width:100, textAlign:'center'}}>{currentOrder.custom_item_qty_7}
             </td>
            </tr>
            
            </>
            }

            {currentOrder.custom_item_name_8 &&
            <>
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_8}
             </td>
             <td style={{width:100, textAlign:'center'}}>{currentOrder.custom_item_qty_8}
             </td>
            </tr>
            
            </>
            }

            {currentOrder.custom_item_name_9 &&
            <>
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_9}
             </td>
             <td style={{width:100, textAlign:'center'}}>{currentOrder.custom_item_qty_9}
             </td>
            </tr>
            
            </>
            }


            {currentOrder.custom_item_name_10 &&
            <>
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_10}
             </td>
             <td style={{width:100, textAlign:'center'}}>{currentOrder.custom_item_qty_10}
             </td>
            </tr>
            
            </>
            }

         </tbody>
     </table>
     </>
}



                    <span style={{ marginTop: 50, marginLeft: 15 }}><b>Order Comments: </b>{currentOrder.order_comments}</span><br /><br />
                    <span style={{ marginTop: 10, marginLeft: 15 }}><b>Mode of Delivery: </b>{currentOrder.mode_of_delivery}</span>


                    <div style={{ marginTop: 30, marginLeft: 15 }}>

                      <p><b>Order Status:</b> {currentStatus}</p>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={openModal}
                        style={{
                          width: '20%', height: 35, marginRight: '1%', paddingTop: 5, backgroundColor: '#4169E1',
                          float: 'left', alignSelf: 'flex-start'
                        }}>
                        Change Order Status
                      </Button>
                    </div>

                    <hr />
                    <div style={{ marginTop: 30, marginLeft: 15 }}>

                      <h3 style={{ marginBottom: 25 }}>Order Timeline</h3>

                      <div style={{ backgroundColor: '#E8E8E8', padding: 10, borderRadius: 5, marginTop: 20 }}>
                        <p style={{ fontWeight: 'bold' }}>Order was placed</p>
                        <p>{new Date(currentOrder.created_at).toDateString()}</p>
                      </div>

                      {currentOrder.order_updates.reverse().map((orderUpdate) => (

                        <div style={{ backgroundColor: '#E8E8E8', padding: 10, borderRadius: 5, marginTop: 20 }}>
                          <p style={{ fontWeight: 'bold' }}>Order status was changed from {orderUpdate.previous_status} to {orderUpdate.changed_status}</p>
                          <p>{new Date(orderUpdate.created_at).toDateString()}</p>
                          {orderUpdate.comment &&
                            <p><b>Comment: </b>{orderUpdate.comment}</p>
                          }
                        </div>

                      ))}

                    </div>

                    <div style={{ float: 'right', marginBottom: 20, marginRight: 50, marginTop: 50 }}>

                      <h3 style={{ textAlign: 'right' }}>
                        Order Total : {currentOrder.order_total}</h3>

                    </div>
                  </div>

                  <ReactToPrint
                    trigger={() => <button className="btn9"
                      documentTitle={'Order' + '#000' + currentOrder.id + '.pdf'}
                      pageStyle={pageStyle}
                      style={{
                        width: '20%', marginRight: '1%', height: 35,
                        borderRadius: 4,
                        float: 'right', alignSelf: 'flex-end', backgroundColor: '#F05225',
                        border: 'none', fontWeight: 500, color: 'white', fontSize: '0.875rem'
                      }}>
                      PRINT ORDER</button>}
                    content={() => componentRef.current}
                  />


                  <Button
                    variant="contained"
                    color="primary"
                    onClick={backToOrders}
                    style={{
                      width: '20%', height: 35, marginRight: '1%', paddingTop: 10,
                      float: 'right', alignSelf: 'flex-end'
                    }}>
                    Back to Orders
                  </Button>                  

                  <JsonToExcel
                    title="EXPORT TO TALLY FORMAT"
                    data={orderItems}
                    fileName={"tally-export_order_"+currentOrder.id}
                    btnClassName="custom-classname export-to-tally-button"
                    style={{paddingTop:10}}
                  />


                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleExportCSV}
                    style={{
                      width: '20%', height: 35, marginRight: '1%', paddingTop: 10,
                      float: 'right', alignSelf: 'flex-end', backgroundColor:'orange', color:'black'
                    }}>
                    Export to CSV
                  </Button>



                </div>
              }

            </div>
          }
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        {currentOrder &&
          <div style={{ marginTop: 30, marginLeft: 15, display: 'flex', flexDirection: 'column' }}>

            <label>Order Status</label><br />
            <select className="form-control"
              defaultValue={currentOrder.status}
              onChange={(event) => setOrderStatus(event.target.value)}>

              <option value="Order Placed">Order Placed</option>
              <option value="Received">Received</option>
              <option value="In Process">In Process</option>
              <option value="Partially Dispatched">Partially Dispatched</option>
              <option value="Fully Dispatched">Fully Dispatched</option>
            </select>

            {statusError &&
              <p style={{ color: 'red' }}>Please change the status before submitting</p>
            }

            <textarea className="form-control"
              style={{ marginTop: 30, height: 100 }}
              name="comment"
              placeholder="Enter comments here"
              onChange={handleCommentChange}
              id="comment">{comment}
            </textarea>

            {commentsEmpty &&
              <p style={{ color: 'red' }}>Comment is mandatory while changing order status</p>
            }


            <Button
              variant="contained"
              color="primary"
              onClick={(event) => changeOrderStatus(currentOrder.id, comment)}
              style={{
                width: '20%', height: 35, marginRight: '1%', paddingTop: 10, marginTop: 10, backgroundColor: '#4169E1',
                float: 'left', alignSelf: 'flex-start'
              }}>

              {changingStatus ? <CircularProgress style={{ color: 'white', width: 20 }} /> : "SUBMIT"}
            </Button>
          </div>
        }

      </Modal>

    </>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  orders: state.order,
});

const pageStyle = `
  @page {
    size: 148mm 210mm;
    padding:5px;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

export default connect(mapStateToProps, { setOrder })(Orders);
