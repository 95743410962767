//Auth
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const VERIFY_OTP = "VERIFY_OTP";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
 

//Brand
export const CLEAR_BRAND = "CLEAR_BRAND";
export const SET_BRAND = "SET_BRAND";

// Applicable for
export const CLEAR_APPLICABLE_FOR = "CLEAR_APPLICABLE_FOR";
export const SET_APPLICABLE_FOR = "SET_APPLICABLE_FOR";

// Fitment for
export const CLEAR_FITMENT_FOR = "CLEAR_FITMENT_FOR";
export const SET_FITMENT_FOR = "SET_FITMENT_FOR";


// Product bucket
export const CLEAR_PRODUCT_BUCKET = "CLEAR_PRODUCT_BUCKET";
export const SET_PRODUCT_BUCKET = "SET_PRODUCT_BUCKET";

//inventory
export const CLEAR_INVENTORY = "CLEAR_INVENTORY";
export const SET_INVENTORY = "SET_INVENTORY";

//Category
export const CLEAR_CATEGORY = "CLEAR_CATEGORY";
export const SET_CATEGORY = "SET_CATEGORY";

//generic user
export const CLEAR_GENERIC_USER = "CLEAR_GENERIC_USER";
export const SET_GENERIC_USER = "SET_GENERIC_USER";

//showroom user
export const CLEAR_SHOWROOM_USER = "CLEAR_SHOWROOM_USER";
export const SET_SHOWROOM_USER = "SET_SHOWROOM_USER";

//order
export const CLEAR_ORDER = "CLEAR_ORDER";
export const SET_ORDER = "SET_ORDER";