import React, { useEffect } from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// cor
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

// icons
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from '@material-ui/icons/Settings';



// components
import { Link, withRouter } from "react-router-dom";
import { SideBarTabs } from "./SideBarTabs";
import { Tooltip } from "@material-ui/core";

import axios from "axios";
import store from "../../store";

//import logo from "../../../src/assets/img/arihant_logo.jpeg"
import logo from "../../assets/img/arihant_logo.png";

import { connect } from "react-redux";
import { logout } from "../../actions/authAction";
const drawerWidth = 300;

const Logo = "src/assets/img/logo.jpeg"

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: "#F05225",
  },

  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    position: "relative",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  point: {
    color: "white",
    marginLeft: 12,
  },
}));



function MUIDrawer({ history, role, server, access_token, logout, point }) {

  const theme = useTheme();
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const [open, setOpen] = React.useState(matches);
  const [currentMenu, setCurrentMenu] = React.useState('Dashboard');

  const handleDrawerClose = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const SelectedListItem = () => {
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = (event, index) => {
      setSelectedIndex(index);
    };
  };


  useEffect(() => {

    var currentURL = window.location.href;

    if (currentURL.includes('Dashboard')) {
      setCurrentMenu('Dashboard');
    }

    if (currentURL.includes('Categor')) {
      setCurrentMenu('Categories');
    }

    if (currentURL.includes('Brand')) {
      setCurrentMenu('Brands');
    }

    if (currentURL.includes('ApplicableFor')) {
      setCurrentMenu('ApplicableFor');
    }

    if (currentURL.includes('FitmentFor')) {
      setCurrentMenu('FitmentFor');
    }

    if (currentURL.includes('ProductBucket')) {
      setCurrentMenu('ProductBuckets');
    }

    if (currentURL.includes('User')) {
      setCurrentMenu('Users');
    }

    if (currentURL.includes('Inventory') || currentURL.includes('inventory')) {
      setCurrentMenu('Inventory');
    }

    if (currentURL.includes('Order')) {
      setCurrentMenu('Orders');
    }

    if (currentURL.includes('Password')) {
      setCurrentMenu('ChangePassword');
    }

  }, []);


  useEffect(() => {

    setOpen(matches);
  }, [matches]);

  const handleLogOut = () => {
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };
    const parameter = {
      _method: "DELETE",
    };

    axios
      .post('https://arihant.trikodev.xyz/' + "api/auth/logout", parameter, config)
      .then((response) => {
        logout();
        history.push("/");
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const goToSettings = () => {

    history.push('/settings');

 };

  

  return (
    <div className={classes.root} id="SideBar">
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      ></AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar} style={{ position: 'fixed', zIndex: 1000 }}>
          {open && <img src={logo} alt=''
            style={{ width: 200, height: "80%", resizeMode: "contain" }} />}

          <IconButton onClick={handleDrawerClose}>
            <MenuIcon className="breadcrum" style={{color:'black'}}/>
          </IconButton>

        </div>
        
        <Divider />

        <List className="h-100" style={{ marginTop: 80 }}>

          {SideBarTabs.map((item, index) =>
            <Tooltip
              title={item.title}
              placement="right"
              arrow
              disableHoverListener={open}
            >

              <div>
                {currentMenu == 'Dashboard' && item.title == 'Dashboard'
                  || currentMenu == 'Categories' && item.title == 'Categories'
                  || currentMenu == 'Brands' && item.title == 'Brands'
                  || currentMenu == 'ApplicableFor' && item.title == 'Applicable For'
                  || currentMenu == 'FitmentFor' && item.title == 'Fitment For'
                  || currentMenu == 'ProductBuckets' && item.title == 'Product Bucket'
                  || currentMenu == 'Users' && item.title == 'Users'
                  || currentMenu == 'Inventory' && item.title == 'Inventory'
                  || currentMenu == 'Orders' && item.title == 'Orders'
                  || currentMenu == 'ChangePassword' && item.title == 'Change Password'


                  ?
                  <ListItem button key={index} style={{
                    display: 'flex',
                    fontFamily: 'Open Sans', alignItems: 'flex-start', backgroundColor: '#F05225'
                  }} >

                    <Link
                      className="d-flex flex-grow-1 iconContainer"
                      activeClassname={classes.active}
                      to={item.path}
                      style={{
                        alignSelf: 'flex-start', textDecoration: 'none',
                        color: 'white'
                      }}
                    >
                      <ListItemIcon sx={{ color: 'black' }}>{item.icons} </ListItemIcon>
                      {open && <span style={{
                        textAlign: 'left', textTransform: 'uppercase',
                        paddingTop: 2, marginLeft: 4
                      }}>{item.title}</span>}
                    </Link>
                  </ListItem>
                  :
                  <ListItem button key={index} style={{
                    display: 'flex',
                    fontFamily: 'Open Sans', alignItems: 'flex-start',
                  }} >

                    <Link
                      className="d-flex flex-grow-1 iconContainer"
                      activeClassname={classes.active}
                      to={item.path}
                      style={{
                        alignSelf: 'flex-start', textDecoration: 'none',
                        color: '#212529'
                      }}
                    >
                      <ListItemIcon>{item.icons} </ListItemIcon>
                      {open && <span style={{
                        textAlign: 'left', textTransform: 'uppercase',
                        paddingTop: 2, marginLeft: 4
                      }}>{item.title}</span>}
                    </Link>
                  </ListItem>
                }

              </div>

            </Tooltip>

          )}

          <button
            onClick={goToSettings}
            style={{ marginLeft: 10, marginTop: 30, cursor: 'pointer', backgroundColor: 'transparent', color: 'white', border: 'none' }}>

            {open ? (              
              
              <p className="nav-btn" style={{ textTransform: 'uppercase', color: '#212529', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <SettingsIcon style={{ marginRight: 2 }} />
      <span>Settings</span>
    </p>
            ) : (
              <Tooltip
                title="Logout"
                placement="right"
                arrow
                style={{color:'#212529'}}
                disableHoverListener={open}
              >
                <SettingsIcon className="signOutBtn nav-btn" />                
              </Tooltip>
            )}
          </button>

          <br />


          <button onClick={() => handleLogOut()}
            style={{ marginLeft: 10, marginTop: 30, marginTop: -30, cursor: 'pointer', backgroundColor: 'transparent', color: 'white', border: 'none' }}>
              <i className="fa fa-pendil"></i>
            {open ? (
              <p className="nav-btn" style={{ textTransform: 'uppercase', color: '#212529', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <ExitToAppIcon style={{ marginRight: 2 }} />
                <span>Log Out</span>
              </p>
            ) : (
              <Tooltip
                title="Logout"
                placement="right"
                arrow
                disableHoverListener={open}
              >
                <ExitToAppIcon className="signOutBtn nav-btn" />
              </Tooltip>
            )}
          </button>

        </List>
      </Drawer>
     
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  point: state.auth.point,
});
export default connect(mapStateToProps, { logout })(withRouter(MUIDrawer));
