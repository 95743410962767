import React, { Component, useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
// import BrandAdd from "./BrandAdd"
import { Link } from "react-router-dom";
import Backbutton from "../../Backbutton";

//Table
import MUIDataTable from "mui-datatables";

import EditIcon from "@material-ui/icons/Edit";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LockIcon from "@material-ui/icons/Lock";
import { formatMs, ListItemIcon, Tooltip } from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from "react-bootstrap-sweetalert";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import axios from "axios";
import { connect } from "react-redux";
import { setOrder } from "../../../actions/OrderAction";
import ReactToPrint from 'react-to-print';
import order from "../../../reducers/order";
import Modal from 'react-modal';
import { CircularProgress } from '@material-ui/core';
import { JsonToExcel } from "react-json-to-excel";


const CreateOrder = ({ history, server, access_token, setOrder, orders }) => {

    const [temp, setTemp] = useState(orders);
    const [alert, setAlert] = useState(null);
    const [isDetailVisible, setIsDetailVisible] = useState(false)
    const [currentOrder, setCurrentOrder] = useState(null)
    const [loading, setLoading] = useState(false);
    const [changingStatus, setChangingStatus] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [orderStatus, setOrderStatus] = useState('')
    const [currentStatus, setCurrentStatus] = useState('')
    const [commentsEmpty, setCommentsEmpty] = useState(false)
    const [statusError, setStatusError] = useState(false)
    const [showCreateOrder, setShowCreateOrder] = useState(false)
    const [isProductsLoading, setIsProductsLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [orderItems, setOrderItems] = useState([])
    const [comment, setComment] = useState('');
    const [customers, setCustomers] = useState([]);
    const [lineItems, setLineItems] = useState([]);
    const [flag, setFlag] = useState(false);
    const [orderComments, setOrderComments] = useState('')
    const [modeOfDelivery, setModeOfDelivery] = useState('')
    const [isError, setIsError] = React.useState(false);
    const [cartError, setCartError] = React.useState(false);
    const [customerError, setCustomerError] = React.useState(false);
    const [onLoading, setOnLoading] = useState(false)
    const [cartString, setCartString] = useState('')
    const [selectedCustomerId, setSelectedCustomerId] = useState(0)


    const handleCommentChange = event => {

        setComment(event.target.value);
    };

    const componentRef = useRef();

    const backToOrders = () => {
        setIsDetailVisible(false)
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const showCreateOrderInterface = () => {

        setShowCreateOrder(true);
    }


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width: '40%',
            transform: 'translate(-50%, -50%)',
        },
    };


    const getCustomers = (e) => {

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        };

        fetch('https://arihant.trikodev.xyz/' + 'api/user/get-customers', requestOptions)
            .then(response => response.json())
            .then(data => setCustomers(data.customers));
    }


    const getAllProducts = () => {

        const config = {
            headers: { Authorization: `Bearer ${access_token}` },
        };

        axios
            .get('https://arihant.trikodev.xyz/' + "api/inventory", config)
            .then((response) => {
                setProducts(response.data);
                setLoading(false);
            })
            .catch((error) => { });
    };


    useEffect(() => {

        getCustomers();
        getAllProducts();
    }, []);


    const submitOrder = (id, comment) => {



        var cartIncomplete = false;

        if (lineItems.length > 0) {

            for (var i = 0; i < lineItems.length; i++) {

                if (lineItems[i].qty == 0 || lineItems[i].product == null) {
                    cartIncomplete = true;
                    break;
                }
            }
        }else{
            cartIncomplete = true;
        }

        if (selectedCustomerId == 0) {

            setCustomerError(true);

        } else if (cartIncomplete == true) { // If there is customer selected     

            setCartError(true);

        } else if (modeOfDelivery == '') { // If there are items added and all have qty filled and product selected

            setIsError(true);

        } else {

            setIsError(false);
            setCartError(false);
            setCustomerError(false);

            setLoading(true);

            var cString = '';

            for (var i = 0; i < lineItems.length; i++) {

                cString = cString + '#' + (lineItems[i].product.id +
                    '*' + lineItems[i].qty +
                    '*' + lineItems[i].product.price +
                    '*' + lineItems[i].product.category_id +
                    '*' + lineItems[i].product.color +
                    '*' + lineItems[i].product.size +
                    '*' + lineItems[i].product.brand_id +
                    '*' + lineItems[i].product.fitment_for +
                    '*' + lineItems[i].product.fitment_name +
                    '*' + lineItems[i].product.fitment_type);
            }

            var formData = new FormData();

            formData.append("cart_string", cString);
            formData.append("customer_id", selectedCustomerId);
            formData.append("order_comments", orderComments);
            formData.append("mode_of_delivery", modeOfDelivery);

            setOnLoading(true);

            const requestOptions = {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': 'Bearer ' + access_token
                }
            };

            fetch('https://arihant.trikodev.xyz/' + 'api/user/admin-place-order', requestOptions)
                .then(response => response.json())
                .then(data => notifySuccess());
        }
    }


    const notifySuccess = () => {

        setCurrentStatus(orderStatus);
        setChangingStatus(false);
        setIsOpen(false);
        setComment('');

        toast('Order placed successfully', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        setLoading(false)

        history.push('/Order');
    }


    const addNewLineItem = () => {

        var theLineItems = [...lineItems];;

        theLineItems.push({ index: lineItems.length + 1, "product": null, "qty": 0 })
        setLineItems(theLineItems)
    }


    const handleProductChange = (event, item) => {

        var productId = parseInt(event.target.value);

        var product = null;

        for (var i = 0; i < products.length; i++) {

            if (products[i].id == productId) {
                product = products[i];
            }
        }

        // Iterate over the line items array, change the product and set the line items array again.
        var theLineItemsArray = [...lineItems];
        var newLineItemsArray = [];

        for (var i = 0; i < theLineItemsArray.length; i++) {

            var theObj = {};

            if (theLineItemsArray[i].index == item.index) {

                if (theLineItemsArray[i].product == null) {

                    theObj.index = theLineItemsArray[i].index;
                    theObj.product = product;
                    theObj.qty = 0;
                } else {

                    theObj.index = item.index;
                    theObj.product = item.product;
                    theObj.qty = item.qty;
                }

            } else {

                theObj.index = theLineItemsArray[i].index;
                theObj.product = theLineItemsArray[i].product;
                theObj.qty = theLineItemsArray[i].qty;
            }
            newLineItemsArray.push(theObj)
        }
        setLineItems(newLineItemsArray);
    }



    const handleQtyChange = (event, item) => {

        // Iterate over the line items array, change the product and set the line items array again.          
        var theLineItemsArray = [...lineItems];
        var newLineItemsArray = [];

        for (var i = 0; i < theLineItemsArray.length; i++) {

            var theObj = {};

            if (theLineItemsArray[i].index == item.index) {

                theObj.index = item.index;
                theObj.product = item.product;
                theObj.qty = parseInt(event.target.value);

            } else {

                theObj.index = theLineItemsArray[i].index;
                theObj.product = theLineItemsArray[i].product;
                theObj.qty = theLineItemsArray[i].qty;
            }

            newLineItemsArray.push(theObj)
        }

        setLineItems(newLineItemsArray);
    }



    //A function for formatting a date to MMddyy
    function formatDate(d) {

        //get the month
        var month = d.getMonth();

        //get the day
        //convert day to string
        var day = d.getDate().toString().padStart(2, '0');

        //get the year
        var year = d.getFullYear();

        //pull the last two digits of the year
        year = year.toString().substr(-2);

        //increment month by 1 since it is 0 indexed
        //converts month to a string
        month = (month + 1).toString().padStart(2, '0');

        //return the string "MMddyy"
        return day + '-' + month + '-' + year;
    }


    const options = {
        filterType: "multiselect",
        selectableRows: false,
        print: false,
        download: true,
    };



    var subTotal = 0;

    for (var i = 0; i < lineItems.length; i++) {

        if (lineItems[i].product !== null) {
            subTotal = subTotal + (parseFloat(lineItems[i].product.price) * parseFloat(lineItems[i].qty))
        }
    }


    return (
        <>
            <div className="dashboardMainDivContainer">
                {alert}
                <div className="card m-3 mt-5">

                    <div className="titles">Create an Order</div>

                    <div style={{ textAlign: "start", marginBottom: 50, marginTop: 50 }}>

                        <h3>Select a Customer</h3>

                        <select className="form-control"
                            value={selectedCustomerId}
                            onChange={event => setSelectedCustomerId(parseInt(event.target.value))}
                            style={{ marginTop: 10, width: 400 }}>

                            <option value={0} disabled>Select a customer to place an order for</option>

                            {customers.map((customer) => (
                                <option value={customer.id}>{customer.name} (#000{customer.id})</option>
                            ))}

                        </select>

                        {customerError &&
                            <span style={{ color: 'red', marginLeft: 20 }} >Please select a customer</span>
                        }

                    </div>

                    <hr style={{ marginBottom: 30 }} />


                    {lineItems.map((item, index) => (
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 40, backgroundColor:'#FDF5E6', padding:20 }} key={index}>


              <div style={{ flexDirection: 'column',  }}>
      
                           <div style={{ flexDirection: 'column' }}>
                                <select className="form-control"
                                    name={'select' + index}
                                    onChange={event => handleProductChange(event, item)}
                                    style={{ marginTop: 0, width: 300, fontSize: 17 }}
                                    value={item.product ? item.product.id : 0}>

                                    <option value={0} disabled>Select a Category</option>

                                    {products.map((product) => (
                                        <option value={product.id}>{product.name}  ||  Size:{product.size}  ||  Color:{product.color}  ||  Fitment:{product.fitment_name}  ||  Model:{product.model}</option>
                                    ))}

                                </select>
                            </div>

                            <div style={{ flexDirection: 'column', marginTop:30 }}>
                                <select className="form-control"
                                    name={'select' + index}
                                    onChange={event => handleProductChange(event, item)}
                                    style={{ marginTop: 0, width: 500, fontSize: 17 }}
                                    value={item.product ? item.product.id : 0}>

                                    <option value={0} disabled>Select a Product</option>

                                    {products.map((product) => (
                                        <option value={product.id}>{product.name}  ||  Size:{product.size}  ||  Color:{product.color}  ||  Fitment:{product.fitment_name}  ||  Model:{product.model}</option>
                                    ))}

                                </select>
                            </div>


                            <div style={{ flexDirection: 'column', marginTop:30 }}>
                                <h5>Select Color</h5>

                                <button style={{backgroundColor:'red', borderRadius:5, color:'white', margin:6, padding:5, border:'none'}}>Red</button>
                                <button style={{backgroundColor:'yellow', borderRadius:5, color:'black', margin:6, padding:5, border:'none'}}>Yellow</button>
                                <button style={{backgroundColor:'black', borderRadius:5, color:'white', margin:6, padding:5, border:'none'}}>Black</button>
                            </div>

                       </div>     


                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 100, marginTop: 0 }}>
                                <b>Unit Price</b>
                                <span style={{ textAlign: 'center' }}>{item.product ? ('₹' + item.product.price) : '---'}</span>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 100, marginTop: 0 }}>
                                <b>Enter Quantity</b>
                                <input name={'qty' + index} type="number" value={item.qty} onChange={event => handleQtyChange(event, item)} />
                            </div>


                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 100, marginTop: 0 }}>
                                <b>Line Total</b>
                                <span style={{ textAlign: 'center' }}>{item.product ? ('₹' + (item.qty * item.product.price)) : '---'}</span>
                            </div>

                            <button style={{backgroundColor:'red', color:'white', paddingLeft:0, paddingRight:0, 
                            marginLeft:50, borderRadius:5, border:'none', width:50, height:50}}>X</button>

                        </div>
                    ))}



                    {cartError &&
                        <span style={{ color: 'red', display: 'block' }}>Please fill all fields in the cart</span>
                    }


                    <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: '#F05225', marginTop: 70 }}
                        onClick={() => addNewLineItem()}
                    >
                        Add New Line Item
                    </Button>

                </div>


                <div style={{ width: '52%', marginTop: 30, marginLeft: 30 }}>

                    <fieldset style={{ border: '1px solid rgba(0,0,0,.125)', borderRadius: 5, backgroundColor: '#ebedf8' }}>
                        <legend><b>Please select a mode of delivery</b></legend>

                        <div>
                            <input type="radio" id="huey" name="drone" value="Walk-in"
                                onChange={(event) => setModeOfDelivery(event.target.value)} />
                            <label for="huey">Walk In</label>
                        </div>

                        <div>
                            <input type="radio" id="dewey" name="drone" value="By Bus" onChange={(event) => setModeOfDelivery(event.target.value)} />
                            <label for="dewey">By Bus</label>
                        </div>

                        <div>
                            <input type="radio" id="louie" name="drone" value="By Transport" onChange={(event) => setModeOfDelivery(event.target.value)} />
                            <label for="louie">By Transport</label>
                        </div>

                        <div>
                            <input type="radio" id="louie" name="drone" value="Tempo Load" onChange={(event) => setModeOfDelivery(event.target.value)} />
                            <label for="louie">Tempo Load</label>
                        </div>
                    </fieldset>

                    {isError &&
                        <p style={{ color: 'red', fontSize: 13 }}>Please select a mode of delivery</p>
                    }

                    <select onChange={(event) => setModeOfDelivery(event.target.value)} style={{ display: 'none', }}>
                        <option value="Walk-in">Walk-in</option>
                        <option value="By Bus">By Bus</option>
                        <option value="By Transport">By Transport</option>
                        <option value="Tempo Load">Tempo Load</option>
                    </select>
                </div>


                <div style={{ marginTop: 100, marginLeft: 30, }}>
                    <label><b>Order Comments</b></label><br />
                    <textarea
                        cols={40}
                        rows={5}
                        value={orderComments}
                        name="order_comments"
                        placeholder="Type any comments you have about this order"
                        style={{
                            width: '50%', marginTop: 5,
                            padding: 10, border: '1px solid rgba(0,0,0,.125)',
                            borderRadius: 5, backgroundColor: '#ebedf8'
                        }}
                        onChange={(event) => setOrderComments(event.target.value)}
                    >

                    </textarea>

                </div>


                <div style={{ float: 'right', marginBottom: 20, marginRight: 50, }}>

                    <h5 style={{ textAlign: 'left' }}>Order Total : <b>₹</b>{subTotal.toFixed(2)}</h5>
                    <h5 style={{ textAlign: 'left' }}>GST Applicable Extra</h5>

                    <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: '#F05225', float: 'right', marginRight: 30, marginTop: 50, marginBottom: 100 }}
                        onClick={() => submitOrder()}
                    >

                        {loading ? <CircularProgress style={{ color: 'white' }} /> : "Place Order"}
                    </Button>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    server: state.auth.server,
    access_token: state.auth.access_token,
    orders: state.order,
});

const pageStyle = `
  @page {
    size: 148mm 210mm;
    padding:5px;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

export default connect(mapStateToProps, { setOrder })(CreateOrder);
