import { SET_BRAND, LOGOUT, CLEAR_BRAND } from "../actions/type";

var initailState=[];

const brand =( state = initailState , action) =>{
    switch (action.type) {
        case SET_BRAND:
            return [
                ...state,
                ...action.payload,
            ];
        case CLEAR_BRAND:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default brand;