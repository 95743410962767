import { SET_CATEGORY, LOGOUT, CLEAR_CATEGORY } from "../actions/type";

var initailState=[];

const category =( state = initailState , action) =>{
    switch (action.type) {
        case SET_CATEGORY:
            return [
                ...state,
                ...action.payload,
            ];
        case CLEAR_CATEGORY:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};
export default category;