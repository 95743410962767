import authReducer from "./auth";
import brandReducer from "./brand";
import inventoryReducer from "./inventory";
import { combineReducers } from "redux";
import categoryReducer from "./category";
import genericUserReducer from "./generic_user";
import showroomUserReducer from "./showroom_user";
import orderReducer from "./order";

const allReducer = combineReducers({
  auth: authReducer,
  brand: brandReducer,
  inventory: inventoryReducer,
  category: categoryReducer,
  generic_user: genericUserReducer,
  showroom_user: showroomUserReducer,
  order: orderReducer,
});

export default allReducer;
