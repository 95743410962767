import { React } from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AdminPanelSettingsOutlinedIcon from "@material-ui/icons/AdminPanelSettingsOutlined";
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LockIcon from "@material-ui/icons/Lock";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import CategoryIcon from '@material-ui/icons/Category';
import ClassIcon from '@material-ui/icons/Class';
import ApprovalIcon from '@material-ui/icons/Approval';
import Inventory2Icon from '@material-ui/icons/Inventory2';
import ProductionQuantityLimitsIcon from '@material-ui/icons/ProductionQuantityLimits';
import BorderColorIcon from '@material-ui/icons/BorderColor';

export const SideBarTabs = [
  {
    title: "Dashboard",
    path: "/Dashboard",
    icons: <DashboardIcon className="sideBarIcon" sx={{color:'black'}} />,
    cName: "nav-text",
    priority: 4,
  },
  {
    title: "Categories",
    path: "/Category",
    icons: <CategoryIcon className="sideBarIcon" />,
    cName: "nav-text",
    priority: 3,
  },
  {
    title: "Brands",
    path: "/Brand",
    icons: <ClassIcon className="sideBarIcon" />,
    cName: "nav-text",
    priority: 2,
  },
  {
    title: "Applicable For",
    path: "/ApplicableFor",
    icons: <ApprovalIcon className="sideBarIcon" />,
    cName: "nav-text",
    priority: 2,
  },
  {
    title: "Fitment For",
    path: "/FitmentFor",
    icons: <ApprovalIcon className="sideBarIcon" />,
    cName: "nav-text",
    priority: 2,
  },
  {
    title: "Product Bucket",
    path: "/ProductBucket",
    icons: <Inventory2Icon className="sideBarIcon" />,
    cName: "nav-text",
    priority: 2,
  },
  {
    title: "Users",
    path: "/Users",
    icons: <PeopleOutlineIcon className="sideBarIcon" />,
    cName: "nav-text",
    priority: 3,
  },
  {
    title: "Inventory",
    path: "/Inventory",
    icons: <ProductionQuantityLimitsIcon className="sideBarIcon" />,
    cName: "nav-text",
    priority: 4,
  },
  {
    title: "Orders",
    path: "/Order",
    icons: <BorderColorIcon className="sideBarIcon" />,
    cName: "nav-text",
    priority: 4,
  },
  {
    title: "Change Password",
    path: "/Password",
    icons: <LockIcon className="sideBarIcon" />,
    cName: "nav-text",
    priority: 2,
  },
];
