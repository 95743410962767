import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Grid,
  TextareaAutosize,
  Typography,
  Box,
  Modal,
} from "@material-ui/core";
import Backbutton from "../../Backbutton";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";
import { CircularProgress } from '@material-ui/core';

import SweetAlert from "react-bootstrap-sweetalert";
import ImageUploader from "react-images-upload";
import FormData from "form-data";

let schema = yup.object({
  fitmentforname: yup.string().required("Name is Required").typeError(""),
});

function FitmentForAdd({ server, history, access_token, role }) {

  const [errortext, seterrortext] = useState("");
  const [alert, setAlert] = useState(null);
  const [image, setImage] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [onLoading, setOnLoading] = React.useState(false);
  const [applicableFors, setApplicableFors] = React.useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedApplicableForIds, setSelectedApplicableForIds] = React.useState([]);

  useEffect(() => {

    getApplicableFors();

    if (role?.id !== 1) {
      if (role?.id === 2) {
        history.push("/Distributer");
      }
      if (role?.id === 3) {
        history.push("/Retailer");
      }
      if (role?.id === 4) {
        history.push("/Games");
      }
    }
  }, []);

  const hideAlert = () => {
    setAlert(null);
  };  

  const getApplicableFors = () => {

    fetch(
      'https://arihant.trikodev.xyz/' + "/api/get-applicable-fors")
      .then((res) => res.json())
      .then((json) => {
        setApplicableFors(json.applicableFors);
      })
  };

  const handleChangeCheckbox = (e) => {

    if (e.target.checked == true && !(selectedApplicableForIds.includes(e.target.name))) {
      var tmp = selectedApplicableForIds;
      tmp.push(e.target.name);
      setSelectedApplicableForIds(tmp);
    } else {

      var tmp = selectedApplicableForIds;
      if (selectedApplicableForIds.includes(e.target.name)) {
        tmp = removeItemOnce(tmp, e.target.name);
      }
      setSelectedApplicableForIds(tmp);
    }
  }


  function removeItemOnce(arr, value) {

    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  const added = () => {
    
    setAlert(
      <SweetAlert success title="Added" onConfirm={hideAlert}>
        Fitment For has been created
      </SweetAlert>
    );
    history.push('/FitmentFor')
  };

  const addNewFitmentFor = (values, resetForm) => {

    setOnLoading(true);

    const bodyParameters = {
      fitment_name: values.fitmentforname,      
    };

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    const data = new FormData();

    Object.keys(bodyParameters).forEach((key) => {
      data.set(key, bodyParameters[key]);
    });

    data.append('applicable_for_ids', selectedApplicableForIds.join(","));   

    axios
      .post('https://arihant.trikodev.xyz/' + "api/fitment-for", data, config)
      .then((response) => {
       
          added();
          resetForm();
          setImage(null);
          seterrortext("");
          setOnLoading(false);
      })
      .catch((error) => {
        const errormessage = error.response.data.errors.alias[0];
        seterrortext(errormessage);
      });
  };

  const handleImageChange = (picture) => {
    setImage(picture);
  };

  return (

    <div className="grayBackgroundBox">
      {alert}
      <div className="card container">
        <Formik
          initialValues={{
            fitmentforname: "",            
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            addNewFitmentFor(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form className="container" onSubmit={handleSubmit}>
              <div>
                <Backbutton />
              </div>
              <div className="title">Add New Fitment For</div>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div>
                    <div className="row">
                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          className="inputField"
                          id="standard-basic"
                          label="Fitment for name*"
                          name="fitmentforname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fitmentforname}
                        />
                        {errors.fitmentforname && (
                          <p style={{ color: "red" }}>{errors.fitmentforname}</p>
                        )}
                      </div>                      
                      </div>


                      <div className="row">
                      <p style={{ color: 'grey', marginTop: 30 }}>Applicable For</p>

                      <div className="col-12 43poicol-md-6 inputFieldContainer">

                        {applicableFors.map((applicableFor, index) => (

                          <div>
                            <input type="checkbox" 
                                   id={applicableFor.id} 
                                   name={applicableFor.id} 
                                   onChange={handleChangeCheckbox} />
                            <label style={{ fontSize: 12 }} for={applicableFor.id}>{applicableFor.name}</label>
                          </div>

                        ))}
                      </div>

                    </div>

                  </div>
                </Grid>
                
              </Grid>
              <div style={{ textAlign: "center" }} className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={!isValid || onLoading}
                  style={{backgroundColor:'#F05225'}}
                  disableElevation
                >
                  {onLoading ? <CircularProgress style={{color:'white'}} /> : "Save Fitment For"}                  
                </Button>
              </div>
            </form>
          )}
        </Formik>
        
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
});

export default connect(mapStateToProps, {})(FitmentForAdd);
